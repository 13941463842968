import React from "react";
import '../App.css';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";



function Petition() {
    return (
        <>
        <Helmet>
          <title>Petition | McKay's Mill Homeowners Association of Franklin, TN</title>
          <meta name="description" content="McKay's Mill Homeowners Association of Franklin, TN" />
          {/* Bootstrap and Popper.js */}
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" />
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js" defer></script>
        </Helmet>
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <div className="container">
      <a className="navbar-brand text-center text-uppercase fw-bolder" href="/">
        MCKAYSMILL-HOA.COM
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              aria-current="page"
              href="https://mckaysmill-hoa.com"
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              href="https://agtnprod.powerappsportals.us/DCA/"
              target="_blank"
            >
              Report
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              href="petition"
            >
              Petition
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              href="legal"
            >
              Legal
            </a>
          </li>
          <li className="nav-item dropdown"></li>
        </ul>
      </div>
    </div>
  </nav>
  {/* Page content*/}
  <div className="container">
    <div className="text-center mt-5">
      <p className="lead text-uppercase fw-bold pt-4">
        MCKAYS MILL HOA- UNETHICAL, UNPROFESSIONAL AND RUDE
      </p>
      <p className="lead text-uppercase">
        1215 Habersham Way, Franklin, TN 37067
      </p>
    </div>
  </div>
  <div className="container py-5">
    <p>
      McKays Mill residences we deserve better! A petition has been established
      to remove the{" "}
      <a
        href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
        target="_blank"
      >
        community manager
      </a>{" "}
      in her official capacity given her continued lack of honesty and egregious
      behavior to many in our community. Living in my community for over two
      decades, this{" "}
      <a
        href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
        target="_blank"
      >
        community manager
      </a>{" "}
      has crossed the line with other individuals and after making false reports
      to law enforcement about me I knew something had to be done. We deserve a
      leader, someone who is ethical and more importantly someone who is honest.
      We deserve someone who sits at a desk with an unlocked door, unclosed
      blinds, someone who actually cares and wants to serve our community fully.
    </p>
    <p>
      The{" "}
      <a
        href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
        target="_blank"
      >
        community manager
      </a>{" "}
      has continually misled and slandered members of our community including
      myself. It's time to remove her from her position and it starts with you.
      CMA Community Management has a 2.0/5.0 on Google Reviews and a 1.0/5.0 on
      Yelp so ask yourself should we have someone from CMA running and managing
      our community? Click below to vote, we need your voice. With your vote we
      will find and establish management who cares about each individual living
      in our community, not a paycheck. If you live in McKays Mill a petition
      form has also been established, you should receive something shortly.
      Thank you.{" "}
    </p>
  </div>
  <div className="container text-center d-grid  mx-auto m-4">
    <a
      href="https://chng.it/RMSgNVwh9R"
      target="blank"
      className="btn btn-primary active"
      type="button"
      id="cc"
      style={{ backgroundColor: "#ef2d2d !important" }}
    >
      SIGN PETITION CLICK HERE
    </a>
    
    <div className="container text-center d-grid  mx-auto m-4">
      If you are having problems with the HOA also consider supporting us by filing with the State Attorneys office by clicking the link below.
    </div>
    <div className="container text-center d-grid  mx-auto m-4">
          <a
            href="https://agtnprod.powerappsportals.us/DCA/"
            target="blank"
            className="btn btn-primary active"
            type="button"
            id="cc"
            style={{ backgroundColor: "#0377fc !important" }}
          >
            SUPPORT US BY FILING A REPORT
          </a>
        </div>
  </div>
  {/* Bootstrap core JS*/}
  {/* Core theme JS*/}
</>

        
        );
    }
    
    export default Petition;