import React from "react";
import '../App.css';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";



function Home() {
    return (
        <>
        <Helmet>
          <title>Home | McKay's Mill Homeowners Association of Franklin, TN</title>
          <meta name="description" content="McKay's Mill Homeowners Association of Franklin, TN" />
          {/* Bootstrap and Popper.js */}
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" />
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js" defer></script>
        </Helmet>
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <div className="container">
      <a className="navbar-brand text-center text-uppercase fw-bolder" href="/">
        MCKAYSMILL-HOA.COM
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              aria-current="page"
              
            >
              <Link to="/">Home</Link>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              href="https://agtnprod.powerappsportals.us/DCA/"
              target="_blank"
            >
              Report
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              href="petition"
            >
              <Link to="/Petition">Petition</Link>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
             
            >
              <Link to="/Legal">Legal</Link>
            </a>
          </li>
          <li className="nav-item dropdown"></li>
        </ul>
      </div>
    </div>
  </nav>
  {/* Page content*/}
  <div className="container">
    <div className="text-center mt-5">
      <p className="lead text-uppercase fw-bold pt-4">
      McKays Mill HOA – Deceptive, Unethical, and Unprofessional
      </p>
      <p className="lead text-uppercase">
        1215 Habersham Way, Franklin, TN 37067
      </p>
    </div>
  </div>
  <div className="container">
    <div className="text-left mt-5 py-5">
      <p>
      The HOA governing our community has adopted an aggressive and concerning approach. In late 2021, I began documenting these issues when the newly appointed board implemented significant changes to the bylaws. These updates were misleadingly embedded within a larger set of documents distributed at the time, creating confusion and mistrust among residents. These actions appear intentional and lack transparency.

      The board has established a well organized network of individuals actively monitoring the community. Unfortunately, dissent or opposition to the board's decisions often results in targeted actions against individuals, as my family and I have personally experienced.

      As we move into 2025, it is clear that accountability and transparency are long overdue. I urge the community to come together and support the removal of Kristy Bowman and President Mike Franklin from the HOA. Our Change.org petition, which now has almost 150 signatures thanks to the incredible efforts of our community members, reflects our shared commitment to fostering a fair and supportive neighborhood.

      Join us in advocating for positive change—click the link below to sign the petition and support your community! 
      </p>
      <div className="container text-center d-grid  mx-auto m-4">
        <a
          href="https://www.change.org/p/mckays-mill-hoa-committee-petition"
          target="blank"
          className="btn btn-primary active"
          type="button"
          id="cc"
          style={{ backgroundColor: "#0377fc !important" }}
        >
          <b>SIGN CHANGE.ORG PETITION</b>
        </a>
      </div>
      
      <p>
        The HOA Committee compensates Kristy Bowman, a CMA Community
        Manager who oversees the McKays Mill community here in franklin, TN.{" "}
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
        Kristy Bowman
        </a>{" "}
        has deliberately ignored communications and unethically misrepresented situations to law enforcement and others to advance her position and support the HOA board's agenda. After discussing the matter with a patrol deputy, it became clear how she had intentionally misled the deputy regarding the situation.

        This behavior exemplifies the pattern of targeting individuals who question or challenge these board members. Despite multiple attempts to reach out via phone and email, my communications were completely disregarded by {" "}
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
          Kristy Bowman
        </a>{" "}
        
        I then reached out to a higher-level manager, Bill Chance at CMA, in an effort to understand why my concerns were being ignored. Instead of a resolution, I received an aggressive phone call from him, during which he threatened to have me trespassed from our community clubhouse for alleged harassment.

        When I asked Bill Chance to clarify the basis of these accusations, he struggled to provide a coherent explanation, eventually claiming that I was "banging on the windows" of the clubhouse—a completely false allegation. These unfounded claims appear to be yet another attempt to target me unfairly.

        It is important to note that both current and former committee members have a history of keeping legal matters quiet. Public records reveal that similar issues have arisen with other community members, highlighting a troubling pattern of behavior. Utilizing{" "}
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
          Kristy Bowman
        </a>{" "}
        To secure their positions, these committee members consistently push the limits of professionalism, community standards, and local law. These actions are actively supported by the HOA president, Mike Franklin, who has permitted unlawful police reporting and continues to enable the Community Manager, Kristy Bowman, to operate through unethical and egregious methods. Her use of attorneys and other threatening tactics further exemplifies the troubling practices under Mr. Franklins leadership.
      </p>
      <p>
        {" "}
        
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
          Kristy Bowman
        </a>{" "}
        has no online presence, including photos, which raises questions about transparency. Her body language speaks volumes—nervous and uneasy during interactions. When I first approached Mrs. Bowman at a board meeting, she walked away without engaging in conversation. Several minutes later, she returned but appeared visibly uncomfortable, even hesitating to shake my hand in the lobby. This behavior begs the question: why such unease?

        CMA Community Management seems to shield these individuals with a team of lawyers, often resorting to legal action as a convenient way to deflect accountability and avoid addressing issues they likely contributed to. Here's a recent review on CMA’s practices for further insight:{" "}
        <a
          href="https://www.google.com/search?q=cma+community+management+ga&rlz=1C1VDKB_enUS1018US1018&ei=KalaY6KLCOiOwbkPo9-eWA&ved=0ahUKEwjiw7_94YD7AhVoRzABHaOvBwsQ4dUDCBA&uact=5&oq=cma+community+management+ga&gs_lcp=Cgdnd3Mtd2l6EAMyBggAEBYQHjICCCYyBQgAEIYDMgUIABCGAzIFCAAQhgMyBQgAEIYDOgoIABBHENYEELADOgQIABBDOgUIABCABDoQCC4QsQMQgwEQxwEQ0QMQQzoKCAAQsQMQgwEQQzoNCC4QsQMQxwEQrwEQQzoHCAAQsQMQQzoFCAAQkQI6DgguEIAEELEDEMcBEK8BOgsIABCABBCxAxCDAToLCC4QgAQQxwEQrwFKBAhBGABKBAhGGABQlwdYmiZgyiZoAXABeACAAZMCiAH5G5IBBjAuMTkuM5gBAKABAcgBCMABAQ&sclient=gws-wiz#lrd=0x88f504e53739c1fd:0x347290c16faf8add,1,,,&topic=mid:/m/01pmzd"
          target="_blank"
        >
          Google Reviews
        </a>{" "}
        page that explains a very similar situation: 'Kimberly Mitchell, The CMA
        property manager from my neighborhood (Bentwater) called my wife and
        harassed, threatened her with court and to get a lawyer and said the ARC
        reviewer will deny my request. She then covered up the truth about other
        homes but selectively enfoced mine. The truth was exposed at an appeals
        meeting but thr HOA protected her with no consequence. The covenants do
        not say that type of behavior is what happens to homeowners. She broke
        the law and violated the covenants. My formal complaint letter went
        unanswered and ignored. CMA business blamed me instead. Avoid this
        embarrassing business.' To no suprise CMA Community Management
        Associates also has a 2.0/5.0 as a rating on Google and once you start
        reading some of the reviews you'll start to question should we have
        someone from this business operating our community? On Yelp they have a
        1.0/5.0 and this provides a very good indicator we should not be
        represented by individuals associated with CMA.
      </p>
      <p>
        CC&amp;R changes are fairly common in communities. CC&amp;R changes for
        the McKays Mill Community occured on 10/05/21 changing approval
        procedures, vehicle parking, tree planting and more. In fact there were
        a total of ten rule adjustments in that one ammendment document alone.
        The Master Board did not properly communicate these ammendments, the
        Master Board committee submitted these ammendments in the same packet
        discussing annual dues and at the end of the document(s) home owners
        were informed by submitting and agreeing to those dues that they would
        essentially be agreeing to the bylaw ammendment changes attached in that letter. 
        You can see the ammendment changes below. Signatures were required but the document was
        falsely presented to homeowners as it was presented at the very end of
        the due documents. Legally the Master Board should have informed home
        owners of these changes in a seperate letter notifying them of these
        ammendments. If these ammendments were important than why were they not
        placed in the very front of the packet? Why were they placed at the end?
        When I asked this question at the board meeting I was told they would
        'need that in writing.' 
        <a
          href="https://mckaysmillhoa.com/wp-content/uploads/2023/03/Ten-Rules-and-Regulations-October-2021.pdf"
          target="_blank"
        >
           CC&amp;R Rule and Regulation Changes
        </a>
      </p>
      <p>
      CMA prioritizes maintaining relationships with those who employ their services rather than addressing the impact their actions have on the community. The HOA committee and its members allocate a general salary to the Community Manager, who, in turn, operates under their directives, often covering up issues like those previously described.

      Bill Chance, the CMA Regional Manager, escalated this behavior by falsely accusing me of harassing Kristy Bowman and trespassing me from the community clubhouse. During our phone conversation, he abruptly ended the call, and despite leaving a voicemail, he failed to respond to any subsequent attempts to contact him. These allegations of harassment are entirely unfounded, and I can provide video evidence to refute these claims.

      
      </p>
      <p>
      With the McKay’s Mill HOA Committee, direct communication from board members is nonexistent—everything is funneled through Kristy Bowman. Board members use her position as a shield, allowing them to make rulings and implement changes without accountability. When questions arise, they remain unanswered.

      I have consistently maintained a professional approach in addressing community concerns, so why am I being ignored and subjected to false reports made to law enforcement? The actions of Kristy Bowman and Bill Chance only reinforce my concerns about the HOA committee’s egregious behavior and lack of transparency.

      Months prior to this situation, I attempted to address two separate issues regarding our home with the board. Their responses were dismissive and aggressive, offering no solutions. Instead of resolving the problems, their actions created additional challenges for my family—one of which resulted in a financial burden costing us thousands of dollars.{" "}
      </p>
      <p>
      If you have experienced issues with the McKays Mill HOA, show your support for the community and others by filing a complaint with the state attorney's office through the link below. These abusive actions must be documented and stopped. The HOA committee and its management must be held accountable for their behavior. Let your representatives know and make your voice heard.
      </p>
      <div className="container text-center d-grid  mx-auto m-4">
        <a
          href="https://www.change.org/p/mckays-mill-hoa-committee-petition"
          target="blank"
          className="btn btn-primary active"
          type="button"
          id="cc"
          style={{ backgroundColor: "#0377fc !important" }}
        >
          <b>SIGN CHANGE.ORG PETITION</b>
        </a>
      </div>
      
      <div className="container text-center d-grid  mx-auto m-4">
        <a
          href="https://agtnprod.powerappsportals.us/DCA/"
          target="blank"
          className="btn btn-primary active"
          type="button"
          id="cc"
          style={{ backgroundColor: "#0377fc !important" }}
        >
          <b>FILE STATE ATTORNEY REPORT</b>
        </a>
      </div>
    </div>
  </div>
  <div className="container p-4" />
  {/* Bootstrap core JS*/}
  {/* Core theme JS*/}
</>

        
        );
    }
    
    export default Home;
    